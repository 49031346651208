import './widgetSm.css';
import { Visibility } from '@material-ui/icons';
import { ALLUSERADMIN_QUERY } from '../../graphql/Mutations';
import { useQuery } from '@apollo/client';
import { Button, Grid } from '@material-ui/core';
import {Modal, Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useState } from 'react';

export default function WidgetSm() {
  const { _, data, refetch } = useQuery(ALLUSERADMIN_QUERY);

  if (data) {
    var users = [...data.allUserGet].reverse();
    var limitUser = users.slice(0,users.length > 10 ? 10 : users.length);
  }

  // formate to date 
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
//
// Render the user limit 10 List
const listUser =()=>{
  const ListingUser = limitUser.map((user,idx)=>{
   return  <li key={idx} className="widgetSmListItem">
      <div className="widgetSmUser">
        <span className="widgetSmUsername">
          {user.username}
        </span>
        <span className="widgetSmUserTitle">
          {user.email}
        </span>
      </div>
      <span className='widgetSmUserdate'>{formatDate(user.createdAt)}</span>
    </li>
  });
  return ListingUser;
} 

const listAllUser=()=>{
  const ListingAllUser = users.map((user,idx)=>{
    return  <li key={idx} className="widgetSmListItem">
       <div className="widgetSmUser">
         <span className="widgetSmUsername">
           {user.username}
         </span>
         <span className="widgetSmUserTitle">
           {user.email}
         </span>
       </div>
       <span className='widgetSmUserdate'>{formatDate(user.createdAt)}</span>
     </li>
   });
   return ListingAllUser;
}
const [open, setOpen] = useState(false);
const handleOpen = () => setOpen(true);
const handleClose = () => setOpen(false);
const style = {
  height: "590px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 0,
  borderRadius: "10px",
};

  return (
    <div className="widgetSm">
      <ul className="widgetSmList">
        <li className="widgetSmListItem">
          <div className="widgetSmUser">
            <span className="widgetSmTitle">List of Members</span>
          </div>          
          <span className="widgetSmTitle" style={{marginRight:"20px"}}>Date</span>
        </li>

        {data ? listUser() : ""}
      </ul>
      { data && data?.allUserGet?.length > 10 ?
        <Grid style={{display:"flex",justifyContent:"flex-end"}}>
          <Button onClick={handleOpen} className='admin-userlisting-btn1' style={{background: "#e0e0e0",width:"100px",marginTop:"25px",fontSize:"16px",textTransform:"inherit"}}>See More</Button>
        </Grid> : ""
      }

      <Modal
        style={{ overflow: "auto" }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="join-adminlist-model">
        <div className="widgetSm" style={{height:"90%",overflow:"auto"}}>
        <ul className="widgetSmList">
          <li className="widgetSmListItem">
            <div className="widgetSmUser">
              <span className="widgetSmTitle">List of Members</span>
            </div>          
            <span className="widgetSmTitle" style={{marginRight:"20px"}}>Date</span>
          </li>

        {data ? listAllUser() : ""}
      </ul>
      </div>
      <Grid style={{display:"flex",justifyContent:"flex-end"}}>
          <Button onClick={handleClose}
          className='admin-closelist-model-btn'
           style={{background: "red",
           width:"90px",
           marginTop:"10px",
           marginRight:"30px",
           fontSize:"16px",
           textTransform:"inherit",
           color:"white"
           }}>
            Close</Button>
        </Grid>
        </Box>
      </Modal>

    </div>
  );
}
