import { gql } from "@apollo/client";

// Upload image to single file schema in third section schema
export const UPLOAD_FILE = gql`
  mutation singleUpload($file: Upload!) {
    singleUpload(file: $file) {
      url
    }
  }
`;

// Upload image to singleUploadFileOne schema in second section
export const UPLOAD_FILEONE = gql`
  mutation singleUploadFileOne($fileName: Upload!) {
    singleUploadFileOne(fileName: $fileName) {
      url
    }
  }
`;

// Upload top right image schema in first section
export const UPLOAD_TOP_RIGHT_IMAGE = gql`
  mutation UploadTopRightImage($fileName: Upload!) {
    UploadTopRightImage(fileName: $fileName) {
      url
    }
  }
`;

export const ADMIN_MUTATION = gql`
  mutation adminLogin($email: String!, $password: String!) {
    adminLogin(email: $email, password: $password) {
      id
      token
      email
      username
      role
    }
  }
`;

export const ADMIN_Landing = gql`
  mutation langdingPage(
    $header: String
    $subHeader: String
    $line1: String
    $subline1: String
    $line2: String
    $subline2: String
    $line3: String
    $subline3: String
    $para1: String
    $subline31: String
    $para31: String
    $subline32: String
    $para32: String
    $subline33: String
    $para33: String
    $line4: String
    $subline4: String
    $line5: String
    $subline5: String
  ) {
    langdingPage(
      header: $header
      subHeader: $subHeader
      line1: $line1
      subline1: $subline1
      line2: $line2
      subline2: $subline2
      line3: $line3
      subline3: $subline3
      para1: $para1
      subline31: $subline31
      para31: $para31
      subline32: $subline32
      para32: $para32
      subline33: $subline33
      para33: $para33
      line4: $line4
      subline4: $subline4
      line5: $line5
      subline5: $subline5
    ) {
      header
      subHeader
      line1
      subline1
      line2
      subline2
      line3
      subline3
      para1
      subline31
      para31
      subline32
      para32
      subline33
      para33
      line4
      subline4
      line5
      subline5
    }
  }
`;

export const ADMIN_CMS = gql`
  mutation singleUpload($file: Upload!) {
    singleUpload(file: $file) {
      url
    }
  }
`;

export const ADMIN_UPLOAD = gql`
  mutation uploadfile($fileName: String) {
    uploadfile(fileName: $fileName) {
      url
    }
  }
`;

export const ADMIN_PRICE = gql`
  mutation priceChange($price: String) {
    priceChange(price: $price) {
      price
    }
  }
`;

export const ALLUSERADMIN_QUERY = gql`
  {
    allUserGet {
      username
      email
      premiumMember
      createdAt
    }
  }
`;
