import React, { useReducer, createContext } from 'react';
import jwtDecode from 'jwt-decode';

const initialState = {
  user: null,
  chatUserName: null,
  chatUserAvatar: null,
  roomChatId: null,

  membersChatId: null,
  callHost: false,
  callCreator: false,
};

if (localStorage.getItem('jwtToken')) {
  const decodedToken = jwtDecode(localStorage.getItem('jwtToken'));

  if (decodedToken.exp * 1000 < Date.now()) {
    localStorage.removeItem('jwtToken');
  } else {
    initialState.user = decodedToken;
  }
}

if (localStorage.getItem('chatUserName')) {
  initialState.chatUserName = localStorage.getItem('chatUserName');
}

const AuthContext = createContext({
  user: null,
  roomChatId: null,
  chatUserName: null,
  chatUserAvatar: null,
  membersChatId: null,
  callHost: false,
  callCreator: false,
  updateRoomChatId: (roomName) => {},
  updateChatUserName: (username) => {},
  updateChatUserAvatar: (username) => {},
  updateMembersChatId: (membersId) => {},
  updateCallHost: () => {},
  updateCallCreator: () => {},
  login: (userData) => {},
  logout: () => {},
});

function authReducer(state, action) {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        user: action.payload,
      };

    case 'UPDATE_ROOM_CHAT_ID':
      return {
        ...state,
        roomChatId: action.payload,
      };

    case 'UPDATE_MEMBERS_CHAT_ID':
      return {
        ...state,
        membersChatId: action.payload,
      };
    case 'UPDATE_CALL_HOST':
      return {
        ...state,
        callHost: true,
      };
    case 'UPDATE_CHAT_USER_NAME':
      return {
        ...state,
        chatUserName: action.payload,
      };
    case 'UPDATE_CHAT_USER_AVATAR':
      return {
        ...state,
        chatUserAvatar: action.payload,
      };
    case 'UPDATE_CALL_CREATOR':
      return {
        ...state,
        callCreator: true,
      };
    case 'LOGOUT':
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
}

function AuthProvider(props) {
  const [state, dispatch] = useReducer(authReducer, initialState);

  function login(userData) {
    localStorage.setItem('jwtToken', userData.token);
    dispatch({
      type: 'LOGIN',
      payload: userData,
    });
  }

  function updateRoomChatId(roomName) {
    dispatch({
      type: 'UPDATE_ROOM_CHAT_ID',
      payload: roomName,
    });
  }

  function updateChatUserName(username) {
    dispatch({
      type: 'UPDATE_CHAT_USER_NAME',
      payload: username,
    });
  }

  function updateChatUserAvatar(username) {
    function capFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function generateName() {
      var first = capFirst(username);

      return first;
    }

    let generatedname = generateName();

    const generatedAvatar =
      'https://ui-avatars.com/api/?name=' +
      generatedname +
      '?size=100&rounded=true&uppercase=true&bold=true&background=edab63&color=FFF';
    dispatch({
      type: 'UPDATE_CHAT_USER_AVATAR',
      payload: generatedAvatar,
    });
  }
  function updateMembersChatId(membersId) {
    dispatch({
      type: 'UPDATE_MEMBERS_CHAT_ID',
      payload: membersId,
    });
  }

  function updateCallHost() {
    dispatch({
      type: 'UPDATE_CALL_HOST',
    });
  }

  function updateCallCreator() {
    dispatch({
      type: 'UPDATE_CALL_CREATOR',
    });
  }

  function logout() {
    localStorage.removeItem('jwtToken');
    sessionStorage.clear()
    dispatch({ type: 'LOGOUT' });
  }

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        callHost: state.callHost,
        callCreator: state.callCreator,
        chatUserName: state.chatUserName,
        chatUserAvatar: state.chatUserAvatar,
        roomChatId: state.roomChatId,
        membersChatId: state.membersChatId,
        updateRoomChatId,
        updateMembersChatId,
        updateChatUserAvatar,
        updateChatUserName,
        updateCallCreator,
        updateCallHost,
        login,
        logout,
      }}
      {...props}
    />
  );
}

export { AuthContext, AuthProvider };
