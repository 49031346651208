import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import React, { useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { AuthContext } from "../context/auth";
import "./HeaderSecondary.css";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import * as ROUTES from "../constants/Routes";
import newLogo from "../assets/Meshed.png";
import newLogo1 from "../assets/logo_square_jpg_header.png";
import Grid from "@material-ui/core/Grid";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
const Swal = require('sweetalert2');
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  toolbarMargin: {
    ...theme.mixins.toolbar,
    marginBottom: "3em",
    [theme.breakpoints.down("md")]: {
      marginBottom: "2em",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "1.25em",
    },
  },
  logo: {
    height: "8em",
    [theme.breakpoints.down("md")]: {
      height: "7em",
    },
    [theme.breakpoints.down("xs")]: {
      height: "5.5em",
    },
  },
  logoContainer: {
    padding: "0px",
    // marginLeft: "75px",
    textTransform: "none",

    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  tabContainer: {
    marginLeft: "auto",
  },
  tab: {
    ...theme.typography.tab,
    minWidth: 10,
    marginLeft: "55px",
    "&:hover": {
      color: "rgba(138, 113, 255, 1)!important",
      border:"none"
    },
  },
  button: {
    ...theme.typography.estimate,
    borderRadius: "50px",
    marginLeft: "50px",
    width: "120px",
    textTransform: "none",
    background: "black",

    marginRight: "60px",
    height: "40px",
    border:"3px solid rgba(126, 55, 182, 1)",
    height: "40px",
    "&:hover": {
      background: "linear-gradient(90deg, #824EB3 0%, #DA39C5 100%)",
      border:"none"
    },
  },
  menu: {
    backgroundColor: theme.palette.common.blue,
    color: "white",
    borderRadius: "0px",
  },
  menuItem: {
    ...theme.typography.tab,
    opacity: 0.7,
    "&:hover": {
      opacity: 1,
    },
  },
  drawerIcon: {
    height: "50px",
    width: "50px",
  },
  drawerIconContainer: {
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  drawer: {
    backgroundColor: theme.palette.common.blue,
  },
  drawerItem: {
    ...theme.typography.tab,
    color: "white",
    opacity: 0.7,
  },
  drawerItemEstimate: {
    backgroundColor: theme.palette.common.orange,
  },
  drawerItemSelected: {
    "& .MuiListItemText-root": {
      opacity: 1,
    },
  },
  appbar: {},
}));

function Header(props) {
  const classes = useStyles();
  const context = useContext(AuthContext);
  const theme = useTheme();

  const {
    loading: profileLoading,
    data: profileData,
    refetch: profileRefetch,
  } = useQuery(GET_PROFILE);

  // profileData?.getUser[0]?.username;
// Logout user 
  let history = useHistory(); 
  const LogoutUser=()=>{
    Swal.fire(
      'You have been successfully logged out.',
      'Return to Homepage.',
      'success'
    ).then((result) => {
      context.logout();
      history.push("/");
    })
  }

  const username = profileData?.getUser[0]?.username.split(" ")[0];
  const routes = [
    { name: "Pricing", link: ROUTES.PRICING },
    {
      name:
        context.user && context.user?.role !== "admin" ? (
          <div style={{}}>
            <AccountCircleIcon style={{ position: "relative", top: "0.2em" }} />
            {username}
          </div>
        ) : (
          "Login"
        ),
      link:
        context.user && context.user?.role !== "admin"
          ? ROUTES.PROFILE
          : ROUTES.LOGIN,
    },
  ];

  const tabs = (
    <React.Fragment>
      <Tabs
        value={props.value}
        style={{
          // marginTop: "25px",
          // height: "70px",
        }}
        className={`${classes.tabContainer} header-tabs`}
        indicatorColor="primary"
      >
        {routes.map((route, index) => (
          <Tab
            key={`${route}${index}`}
            className={`${classes.tab} header-link`}
            style={{
              color: "#fff",
              textTransform: "none",
              fontSize: "1rem",
              fontWeight: "bold",
            }}
            component={Link}
            to={route.link}
            label={route.name}
          />
        ))}
      </Tabs>
      {context.user && context.user?.role !== "admin" ? (
        <Button
          component={Link}
          // to="/"
          variant="contained"
          color="secondary"
          style={{}}
          className={`${classes.button} header-btn`}
          onClick={() => {LogoutUser()}}
        >
          Logout
        </Button>
      ) : (
        <Button
          component={Link}
          to={ROUTES.SIGNUP}
          variant="contained"
          color="secondary"
          className={`${classes.button} header-btn`}
        >
          Sign Up
        </Button>
      )}
    </React.Fragment>
  );
  return (
    <React.Fragment>
      <AppBar
        position="sticky"
        className={classes.appbar}
        style={{
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      >
        <Toolbar style={{ background: "#131120" }} disableGutters>
          <Button
            component={Link}
            to="/"
            disableRipple
            className={`${classes.logoContainer} header-logo`}
            style={{ position: "relative" }}
          >
            <img
              style={{
                height: "10em",
                width: "17em",
                display: "inline-block",
                overflow: "hidden",
              }}
              src={newLogo1}
              alt="logo"
              className="header-logo-image"
            ></img>
          </Button>

          {tabs}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

const GET_PROFILE = gql`
  {
    getUser {
      role
      premiumMember
      roomsHost
      email
      username
    }
  }
`;

export default Header;
