import './widgetLg.css';
import { ADMIN_Transaction } from '../../graphql/Query';
import { useQuery } from '@apollo/client';
import { Grid,Button } from '@material-ui/core';
import {Modal, Box } from "@material-ui/core";
import { useState } from 'react';

export default function WidgetLg() {
  const { _, data, refetch } = useQuery(ADMIN_Transaction);
  if (data) {
    var transactions = [...data.getLatest].reverse();
  }
  const Button1 = ({ type }) => {
    return <button className={'widgetLgButton ' + type}>{type}</button>;
  };


  const dateFormate = (timestamp)=>{
    const date = new Date(timestamp);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = date.toLocaleDateString('en-GB', options);
    return formattedDate;
  }

  const listAllTransaction=()=>{
    const ListingAllTransaction = transactions.map((user,idx)=>{
      return  <tr className="widgetLgTr">
                <td className="widgetLgUser">
                  <span className="widgetLgName">
                    {user.name}
                  </span>
                </td>
                <td className="widgetLgDate">
                  {dateFormate(user.date)}
                </td>
                <td className="widgetLgAmount">
                  ${parseFloat(user.amount).toFixed(2)}
                </td>
                <td className="widgetLgStatus">
                  {data?.getLatest?.length >= 1 && user.status === 'COMPLETED' ? (
                    <Button1 type="Approved" />
                  ) : (
                    <Button1 type="Pending" />
                  )}
                </td>
              </tr>
     });
     return ListingAllTransaction;
  }
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    height: "590px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
  
    boxShadow: 24,
    p: 0,
    borderRadius: "10px",
  };


  return (
    <div className="widgetLg">
      <h3 className="widgetLgTitle">Latest transactions</h3>
      <table className="widgetLgTable">
        <tr className="widgetLgTr">
          <th className="widgetLgTh">Customer</th>
          <th className="widgetLgTh">Date</th>
          <th className="widgetLgTh">Amount</th>
          <th className="widgetLgTh">Status</th>
        </tr>
        <tr className="widgetLgTr">
          <td className="widgetLgUser">
            <span className="widgetLgName">
              {data?.getLatest?.length >= 1 ? data?.getLatest?.at(-1)?.name : 'No transactions'}
            </span>
          </td>
          <td className="widgetLgDate">
            {data?.getLatest?.length >= 1 ? dateFormate(data?.getLatest?.at(-1)?.date) : '-'}
          </td>
          <td className="widgetLgAmount">
            ${data?.getLatest?.length >= 1 ? parseFloat(data?.getLatest?.at(-1)?.amount).toFixed(2) : null}
          </td>
          <td className="widgetLgStatus">
            {data?.getLatest?.length >= 1 && data?.getLatest?.at(-1)?.status === 'COMPLETED' ? (
              <Button1 type="Approved" />
            ) : (
              <Button1 type="Pending" />
            )}
          </td>
        </tr>
        <tr className="widgetLgTr">
          <td className="widgetLgUser">
            <span className="widgetLgName">
              {data?.getLatest?.length >= 1 ? data?.getLatest?.at(-2)?.name : 'No Transaction'}
            </span>
          </td>
          <td className="widgetLgDate">
            {data?.getLatest?.length >= 1 ? dateFormate(data?.getLatest?.at(-2)?.date) : '-'}
          </td>
          <td className="widgetLgAmount">
            ${data?.getLatest?.length >= 1 ? parseFloat(data?.getLatest?.at(-2)?.amount).toFixed(2) : null}
          </td>
          <td className="widgetLgStatus">
            {data?.getLatest?.length >= 1 && data?.getLatest?.at(-2)?.status === 'COMPLETED' ? (
              <Button1 type="Approved" />
            ) : (
              <Button1 type="Pending" />
            )}
          </td>
        </tr>
        <tr className="widgetLgTr">
          <td className="widgetLgUser">
            <span className="widgetLgName">
              {data?.getLatest?.length >= 1 ? data?.getLatest?.at(-3)?.name : 'No Transaction'}
            </span>
          </td>
          <td className="widgetLgDate">
            {data?.getLatest?.length >= 1 ? dateFormate(data?.getLatest?.at(-3)?.date) : '-'}
          </td>
          <td className="widgetLgAmount">
            ${data?.getLatest?.length >= 1 ? parseFloat(data?.getLatest?.at(-3)?.amount).toFixed(2) : null}
          </td>
          <td className="widgetLgStatus">
            {data?.getLatest?.length >= 1 && data?.getLatest?.at(-3)?.status === 'COMPLETED' ? (
              <Button1 type="Approved" />
            ) : (
              <Button1 type="Pending" />
            )}
          </td>
        </tr>
        <tr className="widgetLgTr">
          <td className="widgetLgUser">
            <span className="widgetLgName">
              {data?.getLatest?.length >= 1 ? data?.getLatest?.at(-4)?.name : 'No Transaction'}
            </span>
          </td>
          <td className="widgetLgDate">
            {data?.getLatest?.length >= 1 ? dateFormate(data?.getLatest?.at(-4)?.date) : '-'}
          </td>
          <td className="widgetLgAmount">
            ${data?.getLatest?.length >= 1 ? parseFloat(data?.getLatest?.at(-4)?.amount).toFixed(2) : null}
          </td>
          <td className="widgetLgStatus">
            {data?.getLatest?.length >= 1 && data?.getLatest?.at(-4)?.status === 'COMPLETED' ? (
              <Button1 type="Approved" />
            ) : (
              <Button1 type="Pending" />
            )}
          </td>
        </tr>
        <tr className="widgetLgTr">
          <td className="widgetLgUser">
            <span className="widgetLgName">
              {data?.getLatest?.length >= 1 ? data?.getLatest?.at(-5)?.name : 'No Transaction'}
            </span>
          </td>
          <td className="widgetLgDate">
            {data?.getLatest?.length >= 1 ? dateFormate(data?.getLatest?.at(-5)?.date) : '-'}
          </td>
          <td className="widgetLgAmount">
            ${data?.getLatest?.length >= 1 ? parseFloat(data?.getLatest?.at(-5)?.amount).toFixed(2) : null}
          </td>
          <td className="widgetLgStatus">
            {data?.getLatest?.length >= 1 && data?.getLatest?.at(-5)?.status === 'COMPLETED' ? (
              <Button1 type="Approved" />
            ) : (
              <Button1 type="Pending" />
            )}
          </td>
        </tr>
      </table>
      { data && data?.getLatest?.length > 5 ?
        <Grid style={{display:"flex",justifyContent:"flex-end"}}>
          <Button onClick={handleOpen} className='admin-userlisting-btn' style={{background: "#e0e0e0",width:"100px",marginTop:"25px",fontSize:"16px",textTransform:"inherit",marginRight:"80px"}}>See More</Button>
        </Grid> 
        :""
      }
      <Modal
        style={{ overflow: "auto" }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="join-adminlist-model">
        <div className="widgetSm" style={{height:"90%",overflow:"auto"}}>
        <h3 className="widgetLgTitle">List of transactions</h3>
        <table className="widgetLgTable">
          <tr className="widgetLgTr">
            <th className="widgetLgTh">Customer</th>
            <th className="widgetLgTh">Date</th>
            <th className="widgetLgTh">Amount</th>
            <th className="widgetLgTh">Status</th>
          </tr>
          {data ? listAllTransaction() : ""}
        </table>
      </div>
      <Grid style={{display:"flex",justifyContent:"flex-end"}}>
          <Button onClick={handleClose}
          className='admin-closelist-model-btn'
           style={{background: "red",
           width:"90px",
           marginTop:"10px",
           marginRight:"30px",
           fontSize:"16px",
           textTransform:"inherit",
           color:"white"
           }}>
            Close</Button>
        </Grid>
        </Box>
      </Modal>
    </div>
  );
}
