import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Grid from '@material-ui/core/Grid';
import { AuthContext } from '../context/auth';
import Topbar from '../components/topbar/Topbar';
import Sidebar from '../components/sidebar/Sidebar';
import '../Admin.css';

function AdminRoute({ component: Component, ...rest }) {
  const { user } = useContext(AuthContext);
  // console.log('Usre ', user);
  return (
    <Route
      {...rest}
      render={(props) =>
        !user || user.role !== 'admin' ? (
          <Redirect to="/admin/login" />
        ) : (
          <>
            <Topbar />
            <Grid className="container" item container sm={12}>
              <Grid item sm={2}>
              <Sidebar />
              </Grid>
              <Grid item sm={10}>
              <Component {...props} />
              <Toaster />
              </Grid>
              
            </Grid>
          </>
        )
      }
    />
  );
}

export default AdminRoute;
