import React, { useEffect, useState } from "react";
import "./card.css";
import { ADMIN_PRICE } from "../../graphql/Mutations";
import { ADMIN_PRICEDATA } from "../../graphql/Query";
import { useMutation, useQuery } from "@apollo/client";
const Swal = require("sweetalert2");
import { Grid, Card, CardActions, CardContent } from "@material-ui/core";
import { Typography, Button } from "@material-ui/core";
const Card1 = () => {
  const [edit, setEdit] = useState(false);
  const [price, setPrice] = useState(null);

  //Price change Function
  const [priceChange] = useMutation(ADMIN_PRICE, {
    onCompleted: (data) => {
      // console.log("🔥", data);
      Swal.fire({
        icon: "success",
        html: "<b style='font-family:arial'>Price Updated successfully.</b>",
      });
      refetch();
    },
    onError: (err) => console.error(err),
  });
  const { _, data, refetch } = useQuery(ADMIN_PRICEDATA);

  // Price edit function
  const editHandler = () => {
    let editCheck = !edit;
    setEdit(editCheck);
    if (edit) {
      if (price === null) {
        return Swal.fire({
          icon: "error",
          html: "<b style='font-family:arial'>Price field is required.</b>",
        });
      }
      const numberRegex = /^[0-9]+$|^([0-9]+(\.[0-9]+)?)$|^(\.[0-9]+)$/;
      if (numberRegex.test(price)) {
        priceChange({ variables: { price: price } }); //+price
        setPrice(null);
        refetch();
      } else {
        setPrice(null);
        return Swal.fire({
          icon: "error",
          html: "<b style='font-family:arial'>Please enter a valid amount.</b>",
        });
      }
    }
  };

  return (
    <div>
      <Grid
        container
        style={{
          paddingLeft: "2em",
        }}
      >
        <Grid item>
          <Card
            className="card-main-grid"
            style={{
              boxShadow: "2px 3px 10px grey",
              minWidth: "275px",
              maxWidth: "275px",
              marginBottom: "30px",
              height: "400px",
              "&:hover": {
                background: "red",
                color: "white",
              },
            }}
          >
            <CardContent>
              <Typography variant="h4" className="card-main-heading">
                Premium
              </Typography>
            </CardContent>

            <CardContent>
              {" "}
              <Typography variant="h6">
                {edit ? (
                  <div
                    className="loginInput"
                    style={{
                      borderBottom: "2px solid #eee",
                      margin: "20px 0;",
                    }}
                  >
                    <input
                      type="text"
                      // min="1"
                      autofocus
                      placeholder={data?.priceData?.price || 399}
                      value={price}
                      onChange={(event) => {
                        setPrice(event.target.value);
                      }}
                    />
                    <span></span>
                  </div>
                ) : (
                  <h2 className="card-main-rupee">
                    ${data?.priceData?.price || 399}
                  </h2>
                )}
              </Typography>
            </CardContent>
            <CardContent>
              {" "}
              <Typography variant="body1" className="card-main-text">
                ✓ Host up to 100 participants
              </Typography>
              <Typography
                variant="body1"
                style={{ marginTop: "5px" }}
                className="card-main-text"
              >
                ✓ 5 group meetings up to 6 hours
              </Typography>
            </CardContent>

            <CardActions
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                className="price-edit-btn"
                style={{
                  background: "white",
                  color: "black",
                  position: "relative",
                  boxShadow: "1px 1px 3px grey",
                }}
                onClick={editHandler}
              >
                {edit ? "Save" : "Edit"}
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Card1;
