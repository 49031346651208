export const LANDING_PAGE = "/";
export const LOGIN = "/login";
export const SIGNUP = "/signup";
export const PROFILE = "/profile";
export const JOIN_EVENT = "/join-event";
export const CREATE_EVENT = "/create-event";
export const CALL_PAGE = "/call-page";
export const NOT_FOUND = "/not-found";
export const KICKED_PAGE = "/kicked-page";
export const GUESTJOINPAGE = "/join-event/virtual/:roomName";
export const VIPGUESTJOINPAGE = "/join-event/vip/:roomName";
export const PRICING = "/pricing";
export const PRICING1 = "/pricing1";
export const FORGOTPASSWORD = "/forgotpassword";
export const VERIFICATIONCODE = "/verificationcode";
export const SETNEWPASSWORD = "/setnewpassword";
export const RESETDONE = "/resetdone";
//<------------------------------------------->
export const FORGOTPASSWORDADMIN = "/admin/forgotpassword";
export const VERIFICATIONCODEADMIN = "/admin/verificationcode";
export const SETNEWPASSWORDADMIN = "/admin/setnewpassword";
export const RESETDONEADMIN = "/admin/resetdone";
//<------------------------------------------->
export const HOSTJOINPAGE = "/join-event/host/:roomName";
export const CUSTOMJOINPAGE = "/join-event/custom/:roomName/:type";
export const STARTEVENT = "/startEvent";
export const CUSTOMFEED = "/createcustomfeed";
// Admin Routes
export const AUTH_LOGIN = "/auth/login"; //Redirected  /auth to /auth/login.

export const AUTH_USERS = "/users";
export const AUTH_MAINPAGE = "/mainPage";
export const AUTH_HOME = "/home";
//share link screen route
export const SHARE_LINKSCREEN = "/shareLinkScreen";
