import { useEffect } from "react";
import Chart from "../../components/chart/Chart";
import FeaturedInfo from "../../components/featuredInfo/FeaturedInfo";
import "./home.css";
// import { userData } from "../../dummyData";
import WidgetSm from "../../components/widgetSm/WidgetSm";
import WidgetLg from "../../components/widgetLg/WidgetLg";
import { ADMIN_GETMONTH } from "../../graphql/Query";
import { useQuery } from "@apollo/client";
import { Grid } from "@material-ui/core";

const userData = [
  {
    name: "Jan",
    Active: null,
  },
  {
    name: "Feb",
    Active: null,
  },
  {
    name: "Mar",
    Active: null,
  },
  {
    name: "Apr",
    Active: null,
  },
  {
    name: "May",
    Active: null,
  },
  {
    name: "Jun",
    Active: null,
  },
  {
    name: "Jul",
    Active: null,
  },
  {
    name: "Aug",
    Active: null,
  },
  {
    name: "Sep",
    Active: null,
  },
  {
    name: "Oct",
    Active: null,
  },
  {
    name: "Nov",
    Active: null,
  },
  {
    name: "Dec",
    Active: null,
  },
];

export default function Home() {
  const { _, data, refetch } = useQuery(ADMIN_GETMONTH);
  // console.log("data for graph 🚀", data);

  userData[0].Active = data?.monthlyPament?.Jan;
  userData[1].Active = data?.monthlyPament?.Feb;
  userData[2].Active = data?.monthlyPament?.Mar;
  userData[3].Active = data?.monthlyPament?.Apr;
  userData[4].Active = data?.monthlyPament?.May;
  userData[5].Active = data?.monthlyPament?.Jun;
  userData[6].Active = data?.monthlyPament?.Jul;
  userData[7].Active = data?.monthlyPament?.Aug;
  userData[8].Active = data?.monthlyPament?.Sep;
  userData[9].Active = data?.monthlyPament?.Oct;
  userData[10].Active = data?.monthlyPament?.Nov;
  userData[11].Active = data?.monthlyPament?.Dec;
  let dummyarray;
  if (userData[0]?.Active !== null) {
    dummyarray = [...userData];
  }
  // console.log("🔥", dummyarray);

  useEffect(() => {
    // console.log("data for graph 🚀", data);
  }, []);

  return (
    <Grid
      container
      justifyContent="center"
      //  style={{ border: '5px solid black' }}
      // style={{ marginLeft: "14.9%" }}
    >
      <Grid container direction="column" sm={8} lg={7} xl={8}>
        <Grid
          container
          item
          // style={{ border: '1px solid red' }}
        >
          <Grid style={{ width: "100%" }}>
            {" "}
            {/* Incluse the feature information  */}
            <FeaturedInfo />
          </Grid>
          <Grid item sm={12} style={{}}>
            {/* include the Chartin dashboard */}

            <Chart
              data={dummyarray}
              title="Premuim Users"
              grid
              dataKey="Active"
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          style={{
            marginTop: "50px",
            //  border: "1px solid black"
          }}
        >
          {/* include latest transcation data */}

          <WidgetLg />
        </Grid>
      </Grid>

      {/* new Join members section */}
      <Grid
        container
        item
        sm={4}
        lg={5}
        xl={4}
        //  style={{ border: '1px solid black' }}
      >
        <WidgetSm />
      </Grid>
    </Grid>
  );
}
