import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Facebook from "@material-ui/icons/Facebook";
import Linkedin from "@material-ui/icons/LinkedIn";
import Gmail from "@material-ui/icons/Mail";
import "./Footer.css";
import * as ROUTES from "../constants/Routes";
import privacyPolicy from "../../src/assets/privacy_pdf/Privacy_Policy_Load.pdf"
import TermsOfService from "../../src/assets/privacy_pdf/Terms_of_Service_Load.pdf"
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: "#000",
    width: "90%",
    alignSelf: "center",
    marginTop: "54px",
    marginBottom: "54px",
  },
}));
function Footer(props) {
  const classes = useStyles();
  return (
    <div
      style={{
        backgroundColor: "#000",
        display: "flex",
        alignSelf: "flex-end",
        alignItems: "flex-end",
        justifyContent: "center",
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        className={classes.mainContainer}
      >
        <Grid item container direction="row" style={{ marginBottom: "25px" }}>
          <Grid item xs={12} direction="column">
            <Grid item>
              <Typography
                variant="h5"
                align="left"
                className="footer-heading"
                gutterBottom
                style={{ color: "#fff" }}
              >
                MeSHeDInC
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction="row">
          <Grid item xs={6} container align="flex-end" direction="column">
            <Grid item style={{ marginBottom: "10px" }}>
              <Typography
                variant="body1"
                className="footer-text"
                align="left"
                style={{ color: "#fff", fontWeight: "lighter" }}
              >
                Contact : <a className="link-footer" href="mailto:info@meshedinc.com" style={{color:"white",textDecoration:"none"}} target="_blank">info@meshedinc.com</a>
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: "10px" }}>
              <Typography
                variant="h6"
                className="footer-text"
                gutterBottom
                style={{ color: "#fff", fontSize: "1rem" }}
              >
                Follow Us
              </Typography>
            </Grid>
            <Grid item style={{ alignSelf: "flex-start" }}>
              <a className="link-footer"
                href="https://www.facebook.com/Meshedinc" target="_blank" style={{ marginRight: "1em" }}>
                <Facebook
                  color="#fff"
                  className="footer-share-icon"
                  style={{ color: "#fff",fontSize:"2rem" }}
                />
              </a>
              <a href="https://www.linkedin.com/company/meshedinc/?viewAsMember=true" target="_blank" className="link-footer">
                <Linkedin
                  color="#fff"
                  className="footer-share-icon"
                  style={{ color: "#fff",fontSize:"2rem" }}
                />
              </a>
            </Grid>
          </Grid>
          <Grid
            item
            xs={6}
            container
            align="flex-end"
            alignItems="flex-end"
            direction="column"
          >
            <Grid item style={{ marginBottom: "10px" }}>
              <Typography
                variant="body1"
                align="left"
                className="footer-text"
                style={{ color: "#fff", fontWeight: "lighter" }}
              >
               <a className="link-footer" href={TermsOfService} target="_blank" rel="noreferrer" style={{color:"white",textDecoration:"none"}}> Terms & Conditions</a>
              </Typography>
            </Grid>
            <Grid item className="privacy-grid" style={{ marginBottom: "10px",marginRight:"40px" }}>
              <Typography
                variant="h6"
                className="footer-text"
                gutterBottom
                style={{ color: "#fff", fontSize: "1rem" }}
              >
               <a className="link-footer" href={privacyPolicy} target="_blank" rel="noreferrer" style={{color:"white",textDecoration:"none"}}> Privacy Policy </a>
                
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Footer;
