import React, { useState, useContext, useEffect } from "react";
import image from "../../assets/img/logins.svg";
import "./login.css";
import { ADMIN_MUTATION } from "../../graphql/Mutations";
import { AuthContext } from "../../context/auth";

import { useMutation } from "@apollo/client";
import HeaderSecondary from "../../components/HeaderSecondary";
import Footer from "../../components/Footer";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import * as ROUTES from "../../constants/Routes";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import InputLabel from "@material-ui/core/InputLabel";
import ArrowForward from "@material-ui/icons/ArrowForward";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Input from "@material-ui/core/OutlinedInput";

import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import LoginImage from "../../assets/adminlogo.png";
const Swal = require('sweetalert2');
import {CircularProgress} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    ...theme.typography.estimate,
    borderRadius: "10px",
    marginTop: "40px",
    marginLeft: "70px",
    textTransform: "none",
    fontSize: "1rem",
    fontWeight: "bold",
    background: "#A15AF6",
    width: "70%",
    background: "linear-gradient(#A15AF6 0%,#A309D9 100%)",
    height: "50px",
    "&:hover": {
      backgroundColor: "rgba(126, 55, 182, 1)",
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
  border: {
    // borderBottom:"#8A71FF","&:after":{ borderBottomColor:"#8A71FF"}
  },
}));

const Login = (props) => {
  const [open, setOpen] = React.useState(false);

  const context = useContext(AuthContext);
  const [email, setEmail] = useState(); 
  const [password, setPassword] = useState(); 
  const [errors, setErrors] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (context.user && context.user.role === "admin") {
      console.log("Context found ", context.user);
      props.history.push("/admin/home");
    }
  }, [context.user]);

  const [adminUser, { loading }] = useMutation(ADMIN_MUTATION, {
    update(_, { data: { adminLogin: userData } }) {
      setIsButtonDisabled(false); // Disable Circular button
      context.login(userData);
      props.history.push("/admin/home");
    },
    onError(err) {
      console.log("error ", err);
      setIsButtonDisabled(false); // Disable Circular button
      Swal.fire({
        icon: 'error',
        html: "<b style='font-family:arial'>"+err.graphQLErrors[0].message+"</b>",
      });
    },
    variables: {
      email: email,
      password: password,
    },
  });

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const submitHandler = async (e) => {
    // e.preventDefault();
    const regEx = /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/;
    let errorMessage={};
    if(!email){
      errorMessage["email"]="Please enter email address"
    }else if(!email.match(regEx)){
      errorMessage["email"]="Please enter valid email address"
    }else if(!password){
      errorMessage["password"]="Please enter password"
    }else{
      setIsButtonDisabled(true); // Enable Circular button
      adminUser();
    }
    setErrors({...errorMessage}); 
  };

  // Login Admin via press on Enter button
  const loginAdminEnterBtn=(event)=>{
    if (event.key === "Enter") {
      submitHandler();
    }
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <HeaderSecondary />
      <Grid
        container
        className="admin-login-page"
        style={{
          background: "linear-gradient(98.88deg, #1876E6 -76.44%, #A606D8 98%)",
          // border: '5px solid black',
          padding: "5em",
          maxHeight: "100vh",
          // marginTop:"30px"
          // overflow: 'hidden'
        }}
      >
        <Grid
          item
          container
          style={{
            // border: '5px solid black',
            height: "80vh",
            borderRadius: "25px",
          }}
        >
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            sm={6}
            style={{
              // overflow: 'hidden',
              // border: '2px solid black',
              background:
                "linear-gradient(to bottom right,#A15AF6 0%,#A309D9 100%)",
              borderTopLeftRadius: "25px",
              borderBottomLeftRadius: "25px",
              // height: "78.5vh",
            }}
          >
            <img
              src={LoginImage}
              alt="signup banner"
              style={{ width: "60%", height: "60%", resizeMode: "contain" }}
            />
          </Grid>
          <Grid
            item
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{
              // border: '5px solid blue',
              background: "white",
              paddingTop: "5px",
              borderTopRightRadius: "25px",
              borderBottomRightRadius: "25px",
              // height: "78.5vh",
            }}
            sm={6}
          >
            <Grid
              item
              container
              justifyContent="flex-start"
              style={{
                alignSelf: "center",
                marginTop: "3em",
                marginBottom: "2em",
                marginRight: "1em",
                width: "72%",
                // border:"1px solid black"
              }}
            >
              <Grid
                item
                style={
                  {
                    // border:"1px solid black"
                  }
                }
                sm={7}
              >
                <Typography
                  variant="h3"
                  align="left"
                  className="admin-login-heading"
                  gutterBottom
                  style={{ fontWeight: "600", fontFamily: "Inter" }}
                >
                  Log In
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="column"
              style={{
                width: "75%",
                // alignSelf: 'center',
                marginBottom: "3em",
                // border:"1px solid black"
              }}
            >
              <TextField
                className={`${classes.border} username-field`}
                label="Email Address"
                variant="standard"
                color="secondary"
                value={email}
                onChange={(v) => {
                  setEmail(v.target.value);
                  delete errors["email"];
                  setErrors({...errors})
                }}
                labelWidth={60}
                style={{ marginBottom: "10px" }}
              />
              <div severity="error"
               style={{
                color:"red",
                display : errors.email ? "": "none"
                }}>
                  <Typography>{errors.email}</Typography>                
              </div>
              <TextField
                className="email-field"
                label="Password"
                variant="standard"
                color="secondary"
                type="password"
                value={password}
                style={{ borderRadius: "12px" }}
                onChange={(v) => {
                  setPassword(v.target.value);
                  delete errors["password"];
                  setErrors({...errors})
                }}
                onKeyPress={loginAdminEnterBtn}
                labelWidth={60}
                sx={{ borderBottomColor: "#8A71FF" }}
              />
              <div severity="error"
               style={{
                color:"red",
                display : errors.password ? "": "none"
                }}>
                  <Typography>{errors.password}</Typography>                
              </div>
              <Typography
                component={Link}
                to={ROUTES.FORGOTPASSWORDADMIN}
                variant="body2"
                align="left"
                className="forget-link-admin"
                style={{
                  textDecoration: "none",
                  // color: '#F26D21',
                  marginLeft: "10px",
                  marginTop: "10px",
                  fontSize: "0.8rem",
                }}
              >
                Forgot password?
              </Typography>
            </Grid>

            <Grid
              item
              container
              style={{
                alignSelf: "center",
                width: "72%",
                marginBottom: "10px",
              }}
            >
              <Button
                style={{
                  borderRadius: "50px",
                  boxShadow: " 0px 0px 25px 15px  #f0b3ff",
                }}
                fullWidth
                color="secondary"
                variant="contained"
                disabled={isButtonDisabled}
                className={`${classes.button} admin-login-btn`}
                onClick={submitHandler}
              >
                { isButtonDisabled ? <CircularProgress size={38} /> : "Log In " }
              </Button>
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              align="center"
              alignItems="center"
              style={{ alignSelf: "center", width: "72%", marginTop: "45px" }}
            ></Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

export default Login;
