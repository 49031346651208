import React, { useEffect } from "react";
import LandingPage from "../../components/FrontPage/LandingPage";
import "./mainPage.css";

/**
    TODO:   
    1. build main page components and start working or change Like:=-
            * change the text and images in page             
*/

const MainPage = () => {
  useEffect(() => {
    console.log();
  }, []);
  return (
    <div style={{ maxWidth: "100%" }}>
      <div
      //  style={{ marginLeft: "18%" }}
      >
        {/* include landing page which show the front page of dashboard */}
        <LandingPage />
      </div>
    </div>
  );
};

export default MainPage;
