import * as React from "react";

import gql from "graphql-tag";
import { useState, useEffect } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import InputLabel from "@material-ui/core/InputLabel";
import ArrowForward from "@material-ui/icons/ArrowForward";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import { Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "./CouponForm.css";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
const Swal = require('sweetalert2');

const useStyles = makeStyles((theme) => ({
  new: {
    background: "#824EB2",
    marginLeft: "5.9em",
    color: "white",
    "&:hover": {
      background: "#824EB2",
      color: "white",
    },
    fontFamily: " Inter",
  },
}));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default function MultiActionAreaCard() {
  const classes = useStyles();

  const [discount, setDiscount] = useState(null);
  const [price, setPrice] = useState();
  const [coupon, setCoupon] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [errors, setErrors] = useState();
  const [open, setOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const { loading1, data, refetch } = useQuery(GET_COUPON);

  const { _, data: data1 } = useQuery(ADMIN_PRICEDATA);

  const [createCoupon, { loading, data: data2 }] = useMutation(
    CREATE_COUPON,
    {
      update(_, { data }) {
        setCoupon(data?.createCoupon?.couponCode);
      },
      onError(err) {
        console.log("Error", err);
        Swal.fire({
          icon: 'error',
          html: "<b style='font-family:arial'>"+err.graphQLErrors[0].message+"</b>",
        });
      },
      variables: { price, discount, couponCode },
    },
    {
      refetchQueries: [
        {
          query: GET_COUPON,
          variables: {
            price,

            discount,
            couponCode,
          },
        },
      ],
    }
  );

  const handleSubmit = (e) => {
    window.location.reload();
  };

  useEffect(() => {
    setPrice(`${data1?.priceData?.price}`);
  }, [data1]);

  const generateCode = async () => {
    const generateRandomString = async () => {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      const charactersLength = characters.length;
      for (let i = 0; i < 8; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }
    if(discount){
      const res = await generateRandomString();
      setCouponCode(res);
      if(res){
        createCoupon();
      }
    }else{
      Swal.fire({
        icon: 'error',
        html: "<b style='font-family:arial'>Please fill discount on this price !</b>",
      });
    }
  };

  useEffect(() => {
    refetch();
  }, [discount]);

  return (
    <>
     <Snackbar open={open} autoHideDuration={4500} onClose={handleClose}>
        <Alert onClose={handleClose} severity={"error"}>
          {errors ? errors : "Please check your credentials."}
        </Alert>
      </Snackbar>
      <Grid item container style={{ marginTop: "3em" }}>
        <Typography
          variant="h4"
          style={{ marginLeft: "1.5em", fontFamily: " Inter", fontWeight: 600 }}
          className="coumpan-heading"
        >
          {" "}
          Coupons
        </Typography>
      </Grid>

      <div>
        <Grid
          container
          direction="column"
          justifyContent="center"
          style={{
            marginTop: "2em",
            marginBottom: "2em",
            fontFamily: " Inter",
          }}
        >
          <Grid item conatainer direction="row">
            <Grid
              item
              justifyContent="space-around"
              container
              style={{ paddingBottom: "1em", paddingLeft: "3em" }}
            >
              <Typography
                variant="h6"
                className="c-text"
                style={{
                  position: "relative",
                  right: "1em",
                  fontFamily: " Inter",
                }}
              >
                Discount:
              </Typography>
              <FormControl
                variant="outlined"
                className="coupan-discount-form"
                style={{ paddingLeft: "4em" }}
              >
                <InputLabel htmlFor="Discount"></InputLabel>
                <OutlinedInput
                  id="Discount"
                  placeholder="Ex 25 ,30 etc"
                  style={{ borderRadius: "12px" }}
                  onChange={(e) => {
                    setDiscount(e.target.value);
                  }}
                  labelWidth={60}
                />
              </FormControl>
              <Grid
                item
                container
                alignItems="center"
                className="c-text"
                style={{ width: "10%" }}
              >
                {" "}
                %
              </Grid>
            </Grid>
          </Grid>

          <Grid item justifyContent="center" container>
            <Typography
              variant="h6"
              className="coupan-price-text c-text"
              style={{
                position: "relative",
                right: "4em",
                fontFamily: " Inter",
              }}
            >
              Price:
            </Typography>

            <FormControl
              variant="outlined"
              className="coupan-price-form"
              style={{
                paddingLeft: "5.5em",
              }}
            >
              <InputLabel htmlFor="Price"></InputLabel>
              <OutlinedInput
                id="Price"
                placeholder={`${data1?.priceData?.price}`}
                value={price}
                style={{ borderRadius: "12px" }}
                onChange={(v) => {}}
                labelWidth={60}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item container style={{}}>
          <Typography
            variant="h6"
            className="coupan-code-text c-text"
            style={{
              marginLeft: "2.6em",
              fontFamily: " Inter",
            }}
          >
            {" "}
            Coupon Code:
          </Typography>
          <Button
            onClick={generateCode}
            className={`${classes.new} genrate-code-btn`}
          >
            Generate
          </Button>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          style={{ marginTop: "2em" }}
        >
          <Typography style={{ color: "purple" }} variant="h5">
            {coupon}
          </Typography>
        </Grid>
        <br /> <br />
        <Grid item container justifyContent="center">
          <Button
            variant="contained"
            onClick={handleSubmit}
            className="coupan-submit-btn"
            type="submit"
            style={{
              height: "3em",
              width: "8em",
              background: "linear-gradient(90deg,#824EB2,#DA39C5)",
              borderRadius: "3px",
              color: "white",
            }}
          >
            Submit
          </Button>
        </Grid>
      </div>
    </>
  );
}

export const ADMIN_PRICEDATA = gql`
  {
    priceData {
      price
    }
  }
`;

const GET_COUPON = gql`
  {
    getCouponData {
      discount
      couponCode
      price
      discountedPrice
    }
  }
`;

const CREATE_COUPON = gql`
  mutation createCoupon(
    $discount: String!
    $price: String!
    $couponCode: String
  ) {
    createCoupon(
      discount: $discount
      price: $price

      couponCode: $couponCode
    ) {
      price

      discount
      couponCode
    }
  }
`;
