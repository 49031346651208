import './featuredInfo.css';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { ALLUSERADMIN_QUERY } from '../../graphql/Mutations';
import { ADMIN_TRANSACTIONDATA } from "../../graphql/Query";
import { useQuery } from '@apollo/client';

export default function FeaturedInfo() {
  const { _, data, refetch } = useQuery(ALLUSERADMIN_QUERY);
  const { loading, data:transactionPrice, refetch1 } = useQuery(ADMIN_TRANSACTIONDATA);
  return (
    <div className='featured'>
      <div className='featuredItem'>
        <span className='featuredTitle'>Revenue</span>
        <div className='featuredMoneyContainer'>
          <span className='featuredMoney'>${parseFloat(transactionPrice?.totalTransactionData?.transactionPrice).toFixed(2)}</span>
        </div>
      </div>
      <div className='featuredItem'>
        <span className='featuredTitle'>Total Users</span>
        <div className='featuredMoneyContainer'>
          <span className='featuredMoney'>{data?.allUserGet?.length}</span>
        </div>
      </div>
    </div>
  );
}
