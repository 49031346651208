import { gql } from '@apollo/client';

export const ADMIN_GETHEADER = gql`
  {
    getHeaders {
      header
      subHeader
      images
      line1
      subline1
      line2
      subline2
      line3
      subline3
      para1
      subline31
      para31
      subline32
      para32
      subline33
      para33
      line4
      subline4
      line5
      subline5
      imageOne
      topRightImage
    }
  }
`;

export const ADMIN_Transaction = gql`
  {
    getLatest {
      name
      amount
      date
      status
    }
  }
`;

export const ADMIN_PRICEDATA = gql`
  {
    priceData {
      price
    }
  }
`;

export const ADMIN_TRANSACTIONDATA = gql`
  {
    totalTransactionData {
      transactionPrice
    }
  }
`;

export const ADMIN_GETMONTH = gql`
  {
    monthlyPament {
      Jan
      Feb
      Mar
      Apr
      May
      Jun
      Jul
      Aug
      Sep
      Oct
      Nov
      Dec
    }
  }
`;
