import React from "react";
import CouponForm from "../../components/couponForm/CouponForm";
import CouponCard from "../../components/couponCard/CouponCard";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Button, Modal, Box } from "@material-ui/core";
import gql from "graphql-tag";
import { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { CardActionArea, CardActions } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import banner from "../../assets/green.png";
import DeleteIcon from '@mui/icons-material/Delete';
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import toast from "react-hot-toast";
import "./coupon.css";

const useStyles = makeStyles((theme) => ({
  shadow: {
    "&:hover": {
      boxShadow: "2px 3px 10px grey",
    },
  },
  new: {
    "&:hover": {
      color: "black",
    },
  },
  newCard: { color: "white" },
}));

function Coupon() {
  const { loading, data, refetch } = useQuery(GET_COUPON3);
  const { _, data: data1 } = useQuery(ADMIN_PRICEDATA);
  const eventPrice = data1?.priceData?.price;
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [display, setDisplay] = useState(false);
  const [coupon, setCoupon] = useState();
  // Delete the coupon Function
  const [open1, setOpen1] = useState(false);
  const [activeCouponCode,setactiveCouponCode] = useState("");
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () =>{ setOpen1(false); setCoupon(""); }

  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () =>{ setOpen2(false); setactiveCouponCode(""); }

  const [open3, setOpen3] = useState(false);
  const [deactiveCouponCode,setdeactiveCouponCode] = useState("");
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () =>{ setOpen3(false); setdeactiveCouponCode(""); }

  const handleChange = (event) => {

    console.log("Checked Apply")
  };

  const [deleteCouponData, { loading: loading2 }] = useMutation(DELETE_COUPON, {
    update(_, { data }) {
      // console.log("Data ", data);
      setTimeout(() => {
        refetch();
        toast.success("Coupon Deleted Successfully! ");
      }, 2000);
    },
    onError(err) {
      console.log("Error", err);
    },
    variables: { coupon },
  });

  const [activeCouponData, { loading: loading3 }] = useMutation(ACTIVE_COUPON, {
    update(_, { data }) {
      // console.log("Data ", data);
      
      setTimeout(() => {
        refetch();
        toast.success("Coupon Activated Successfully! ");
      }, 2000);
    },
    onError(err) {
      console.log("Error", err);
    },
    variables: { activeCouponCode, eventPrice },
  });

  const [deactiveCouponData, { loading: loading4 }] = useMutation(DEACTIVE_COUPON, {
    update(_, { data }) {
      // console.log("Data ", data);
      
      setTimeout(() => {
        refetch();
        toast.success("Coupon deactivated Successfully! ");
      }, 2000);
    },
    onError(err) {
      console.log("Error", err);
    },
    variables: { deactiveCouponCode},
  });


  useEffect(() => {
    if(coupon){
      handleOpen1()
      // console.log("Delete Coupon Item",coupon)
    }    
  }, [coupon]);

  useEffect(() => {
    if(activeCouponCode){
      handleOpen2()
    }    
  }, [activeCouponCode]);
  useEffect(() => {
    if(deactiveCouponCode){
      handleOpen3()
    }    
  }, [deactiveCouponCode]);
  const [priceLink, setPriceLink] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () =>{
     setOpen(false);
     refetch();
  };

  const [people, setPeople] = useState([]);
  const [modifiedPeople, setModifiedPeople] = useState(null);

  useEffect(() => {
    if (people && people.length > 0) {
      let colors = [
        " linear-gradient(90deg, #6845D3 -0.12%, #EC66BD 99.52%)",
        "linear-gradient(90deg, #1ECFC3 -0.12%, #3FD9D2 99.52%)",
        "linear-gradient(90deg, #FD8B09 -0.12%, #FD8B09 99.52%)",
        "linear-gradient(90deg, #069DD3 -0.12%, #5DD1FC 99.52%)",
        " linear-gradient(90deg, #FE5E9D -0.12%, #FE8384 99.52%)",
        "linear-gradient(90deg, #4E69AE -0.12%, #8A81B8 99.52%)",
      ];
      let random;
      const newArr = [];
      for (let i = 0; i < people.length; i++) {
        random = colors[Math.floor(Math.random() * colors.length)];
        let newData = {
          couponCode: people[i].couponCode,
          discount: people[i].discount,
          price : people[i].price,
          color: random,
          activeCoupon: people[i].activeCoupon,
        };
        newArr.push(newData);
      }
      setModifiedPeople(newArr);
    }else{
      setModifiedPeople([]);
    }
  }, [people]);
  const style = {
    height: "558px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",

    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
  };
  const style1 = {
    height: "358px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 650,
    bgcolor: "background.paper",

    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
  };

  useEffect(() => {
    if (!loading) setPeople(data?.getCouponData);
  }, [loading,data]);

  const generateCode = () => {
    setDisplay(true);
    console.log(people);
    // setCoupon(people.getCouponData.couponCode);
    refetch();
    // createCoupon();
  };

  const notify = () =>
    toast.loading("Uploading...", {
      duration: 2000,
      position: "top-center",
      icon: "💆‍♂️",
    });

  return (
    <div style={{
      //  marginLeft: "14.9%",
       marginTop:"40px" 
       }}>
      <Grid
        container
        // style={{ border: "5px solid green" }}
      >
        <Grid item container direction="row"
        //  style={{ marginLeft: "5%" }}
         >
          <Grid
            item
            container
            sm={10}
            style={{
              //  border: '5px solid yellow',
              marginBottom: "5%",
            }}
          >
            <Grid item style={{ marginRight: "30px" }}>
              {" "}
              <Typography variant="h4" className="coupan-heading">
                {" "}
                Coupons
              </Typography>
            </Grid>
            <Grid item>
              {" "}
              {/* <Button onClick={generateCode}>generate</Button> */}
              <Button
                variant="outlined"
                className="create-coupan-btn"
                style={{
                  background:
                    " linear-gradient(90deg, #a15af6 -0.12%, #a309d9 99.52%)",
                  color: "white",
                }}
                onClick={handleOpen}
              >
                Create
              </Button>
            </Grid>
          </Grid>

          <Grid
            item
            container
            spacing={2}
            direction="row"
            sm={12}
            style={
              {
                //  border: '5px solid blue'
              }
            }
          >
            {/* <button onClick={generateCode}> Click me</button> */}
            {/* <h1>we have {people.length} coupons</h1> */}

            {modifiedPeople?.map((item, index) => {
              const { price, discount, couponCode, color,activeCoupon } = item;
              // console.log(eventPrice,price);
              // let disabled = ""
              // if(eventPrice == price){
              //   disabled = false
              // }else{
              //   disabled = true
              // }
              return (
                <Grid item sm={4} xl={4} style={{}}>
                  <Card
                    className={`${classes.shadow} coopan-main-card`}
                    key={index}
                    style={{
                      // maxWidth: 345,
                      height: "100%",
                      background: color,
                      borderRadius: 10,
                    }}
                  >
                     <Grid item container sm={12} className="card-delete-coupon" style={{padding:"8px"}}>
                      {/* <Grid item sm={6}>
                        {
                          activeCoupon == "true" ?<Button style={{border:"2px solid #FFFFFF",borderRadius:"25px",width:"200px",color:"#FFFFFF",background:"#26BA5F"}}  onClick={()=>{setdeactiveCouponCode(couponCode)}}>De-activate Coupon</Button>
                          :<Button style={{border:"2px solid #FFFFFF",borderRadius:"25px",width:"200px",color:"#FFFFFF",}}   onClick={()=>{setactiveCouponCode(couponCode)}}>Activate coupon</Button>
                        }
                      </Grid> */}
                      <Grid item sm={12}  style={{display:"flex",justifyContent:"flex-end"}}>
                        <Button onClick={()=>{
                          setCoupon(couponCode);
                        }}><DeleteIcon className="delete-coupon-icon" style={{fontSize:"2rem",padding:"5px",background:"#FFFFFF",color:"#FF0000",borderRadius:"25px"}} /></Button>
                      </Grid>
                    </Grid>
                    <CardContent className={classes.newCard}>
                      <Grid container style={{}}>
                        <Grid item xs={7} container style={{}}>
                          <Typography
                            gutterBottom
                            variant="h4"
                            className="discount-price"
                            component="div"
                          >
                            {discount}% Off
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            className="discount-text"
                          >
                            The coupon gives {discount} % discount on the price {price} $
                          </Typography>
                        </Grid>
                        <Grid item xs={5} container justifyContent="center" style={{}}>
                          <img
                            src={banner}
                            className="admin-copun-card"
                            style={{
                              opacity: 0.3,
                              width: "60%",
                              height: "90%",
                            }}
                            alt="banner"
                          ></img>
                        </Grid>
                      </Grid>
                    </CardContent>

                    <CardActions className="crd-action">
                      <Grid
                        item
                        container
                        className="coupan-grid"
                        justifyContent="center"
                        alignItems="center"
                        style={{
                          marginBottom: "1em",
                          background: "white",
                          borderRadius: 10,
                          height: "2.5em",
                        }}
                      >
                        <Typography
                          className="coupan-code"
                          style={{
                            fontFamily: "Inter",
                            fontWeight: 700,
                            color: "grey",
                          }}
                        >
                          Coupon Code:
                        </Typography>

                        <Typography
                          style={{
                            fontFamily: "Inter",
                            fontWeight: 700,
                            color: "black",
                            marginLeft: "1em",
                          }}
                        >
                          {" "}
                          {couponCode}
                        </Typography>
                      </Grid>
                    </CardActions>
                   
                  </Card>
                </Grid>
              );
            })}

            {/* {loading ? (
              <> </>
            ) : (
              <>
                <>
                  {" "}
                  <h1>we have {people[0]?.price} coupons</h1>
                </>
              </>
            )} */}
          </Grid>
        </Grid>
      </Grid>

      <Modal
        style={{ overflow: "auto" }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="coupan-model">
          <CouponForm />
        </Box>
      </Modal>
      <Modal
        style={{ overflow: "auto" }}
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1} className="join-event-model">
          <Grid container style={{    
            paddingBottom: "25px",
            paddingTop: "30px",
            justifyContent: "center"
          }}
          className="landing-page-model">
            <Grid item style={{display:"flex",justifyContent:"center",width:"100%"}}>
              <ReportProblemIcon style={{fontSize:"5rem",color:"#FFCC4D"}} />
            </Grid>
            <Grid item className="lp-heading" style={{display:"flex",justifyContent:"center",width:"100%",marginTop:"20px"}}>
              <typography 
                variant={"h1"}
                gutterBottom
                className="active-event-heading"
                align="left"
                style={{ fontWeight: "600", fontFamily: "Inter",fontSize:"22px" }}
              >
                Are you sure you want to delete this coupon?
              </typography>
            </Grid>
            <Grid item className="lp-heading" style={{display:"flex",justifyContent:"center",width:"100%",marginTop:"20px"}}>
              <typography 
                variant={"body"}
                gutterBottom
                className="active-event-heading"
                align="left"
                style={{ fontWeight: "500", fontFamily: "Inter" }}
              >
                You will have to create a new coupon?
              </typography>
            </Grid>
          </Grid>
          <Grid container style={{
            paddingBottom: "25px",
            justifyContent: "center"
          }}>
            <Grid item className="model-gotit-grid" style={{display:"flex",justifyContent:"center",marginTop:"20px"}}>
              <Button
                style={{
                  borderRadius: "5px",
                  width:"120px",
                  padding:"5px",
                  color:"#824EB2",
                  // background:"linear-gradient(90deg, #824EB3 0%, #DA39C5 100%)",
                  border: "2px solid #824EB2",
                  background:"#fff",
                  textTransform:"inherit"
                }}
                fullWidth
                color="secondary"
                variant="contained"
                className={`model-gotit-btn`}
                onClick={handleClose1}
              >
                Cancel
            </Button>
            <Button
              style={{
                borderRadius: "5px",
                width:"120px",
                padding:"5px",
                marginLeft:"20px",
                background:"linear-gradient(90deg, #824EB3 0%, #DA39C5 100%)",
                textTransform:"inherit"
              }}
              fullWidth
              color="secondary"
              variant="contained"
              className={`model-gotit-btn`}
              onClick={()=>{deleteCouponData();handleClose1();}}
              >
                Delete
            </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        style={{ overflow: "auto" }}
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1} className="join-event-model">
          <Grid container style={{    
            paddingBottom: "25px",
            paddingTop: "30px",
            justifyContent: "center"
          }}
          className="landing-page-model">
            <Grid item style={{display:"flex",justifyContent:"center",width:"100%"}}>
              <ReportProblemIcon style={{fontSize:"5rem",color:"#FFCC4D"}} />
            </Grid>
            <Grid item className="lp-heading" style={{display:"flex",justifyContent:"center",width:"100%",marginTop:"20px"}}>
              <typography 
                variant={"h1"}
                gutterBottom
                className="active-event-heading"
                align="left"
                style={{ fontWeight: "600", fontFamily: "Inter",fontSize:"22px" }}
              >
                Are you sure you want to activate new coupon?
              </typography>
            </Grid>
            <Grid item className="lp-heading" style={{display:"flex",justifyContent:"center",width:"100%",marginTop:"20px"}}>
              <typography 
                variant={"body"}
                gutterBottom
                className="active-event-heading"
                align="left"
                style={{ fontWeight: "500", fontFamily: "Inter" }}
              >
                The previous coupon will be deactivated
              </typography>
            </Grid>
          </Grid>
          <Grid container style={{
            paddingBottom: "25px",
            justifyContent: "center"
          }}>
            <Grid item className="model-gotit-grid" style={{display:"flex",justifyContent:"center",marginTop:"20px"}}>
              <Button
                style={{
                  borderRadius: "5px",
                  width:"120px",
                  padding:"5px",
                  color:"#824EB2",
                  // background:"linear-gradient(90deg, #824EB3 0%, #DA39C5 100%)",
                  border: "2px solid #824EB2",
                  background:"#fff",
                  textTransform:"inherit"
                }}
                fullWidth
                color="secondary"
                variant="contained"
                className={`model-gotit-btn`}
                onClick={handleClose2}
              >
                Cancel
            </Button>
            <Button
              style={{
                borderRadius: "5px",
                width:"120px",
                padding:"5px",
                marginLeft:"20px",
                background:"linear-gradient(90deg, #824EB3 0%, #DA39C5 100%)",
                textTransform:"inherit"
              }}
              fullWidth
              color="secondary"
              variant="contained"
              className={`model-gotit-btn`}
              onClick={()=>{ activeCouponData();handleClose2();}}
              >
                Activate
            </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        style={{ overflow: "auto" }}
        open={open3}
        onClose={handleClose3}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1} className="join-event-model">
          <Grid container style={{    
            paddingBottom: "25px",
            paddingTop: "30px",
            justifyContent: "center"
          }}
          className="landing-page-model">
            <Grid item style={{display:"flex",justifyContent:"center",width:"100%"}}>
              <ReportProblemIcon style={{fontSize:"5rem",color:"#FFCC4D"}} />
            </Grid>
            <Grid item className="lp-heading" style={{display:"flex",justifyContent:"center",width:"100%",marginTop:"20px"}}>
              <typography 
                variant={"h1"}
                gutterBottom
                className="active-event-heading"
                align="left"
                style={{ fontWeight: "600", fontFamily: "Inter",fontSize:"22px" }}
              >
                Are you sure you want to deactivate the coupon?
              </typography>
            </Grid>
            <Grid item className="lp-heading" style={{display:"flex",justifyContent:"center",width:"100%",marginTop:"20px"}}>
              <typography 
                variant={"body"}
                gutterBottom
                className="active-event-heading"
                align="left"
                style={{ fontWeight: "500", fontFamily: "Inter" }}
              >
                The previous coupon is already deactivated
              </typography>
            </Grid>
          </Grid>
          <Grid container style={{
            paddingBottom: "25px",
            justifyContent: "center"
          }}>
            <Grid item className="model-gotit-grid" style={{display:"flex",justifyContent:"center",marginTop:"20px"}}>
              <Button
                style={{
                  borderRadius: "5px",
                  width:"120px",
                  padding:"5px",
                  color:"#824EB2",
                  // background:"linear-gradient(90deg, #824EB3 0%, #DA39C5 100%)",
                  border: "2px solid #824EB2",
                  background:"#fff",
                  textTransform:"inherit"
                }}
                fullWidth
                color="secondary"
                variant="contained"
                className={`model-gotit-btn`}
                onClick={handleClose3}
              >
                Cancel
            </Button>
            <Button
              style={{
                borderRadius: "5px",
                width:"120px",
                padding:"5px",
                marginLeft:"20px",
                background:"linear-gradient(90deg, #824EB3 0%, #DA39C5 100%)",
                textTransform:"inherit"
              }}
              fullWidth
              color="secondary"
              variant="contained"
              className={`model-gotit-btn`}
              onClick={()=>{ deactiveCouponData();handleClose3();}}
              >
                Deactivate
            </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

const GET_COUPON3 = gql`
  {
    getCouponData {
      discount
      couponCode
      price
      discountedPrice
      activeCoupon
    }
  }
`;

const DELETE_COUPON = gql`
  mutation deleteCoupon($coupon: String!) {
    deleteCoupon(coupon: $coupon) {
      deleted
    }
  }
`;
const ACTIVE_COUPON = gql`
  mutation activeCoupon($activeCouponCode: String!, $eventPrice: String!) {
    activeCoupon(activeCouponCode: $activeCouponCode, eventPrice: $eventPrice) {
      activeCoupons
    }
  }
`;

const DEACTIVE_COUPON = gql`
  mutation deactiveCoupon($deactiveCouponCode: String!) {
    deactiveCoupon(deactiveCouponCode: $deactiveCouponCode) {
      activeCoupons
    }
  }
`;

export const ADMIN_PRICEDATA = gql`
  {
    priceData {
      price
    }
  }
`;
export default Coupon;
