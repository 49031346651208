import React, { useContext } from 'react';
import './topbar.css';
import { NotificationsNone, Language, Settings } from '@material-ui/icons';
import { AuthContext } from '../../context/auth';
import { Button } from '@material-ui/core';

export default function Topbar(props) {
  const { logout } = useContext(AuthContext);

  const logoutAdmin = async () => {
    logout();
  };
  return (
    <div className='topbar'>
      <div className='topbarWrapper'>
        <div className='topLeft'>
          <span className='logo'> </span>
        </div>
        <div className='topRight'>
          <Button
            variant="contained"
            color="secondary"
            style={{background: "linear-gradient(180deg, #a15af6 -0.12%, #a309d9 99.52%)",width:"100px",fontSize:"14px"}}
            onClick={logoutAdmin}
            className='admin-logout-btn'
          >
            Logout
          </Button>
        </div>
      </div>
    </div>
  );
}
