import { createTheme } from '@material-ui/core/styles';

const meshedBlue = '#59ACDE';
const meshedOrange = '#F26D21';
// const arcGrey = "#868686";
// const meshedBlack = "#242424";
// const meshedSecondaryBlack = "#444";

export default createTheme({
  palette: {
    common: {
      blue: meshedBlue,
      orange: meshedOrange,
      lightBlack: '#242424',
    },
    primary: {
      main: meshedBlue,
    },
    secondary: {
      main: meshedOrange,
    },
  },
  typography: {
    // tab: {
    //   fontFamily: "Raleway",
    //   textTransform: "none",
    //   fontWeight: 700,
    //   color: "white",
    //   fontSize: "1rem",
    // },
    // estimate: {
    //   fontFamily: "Pacifico",
    //   fontSize: "1rem",
    //   textTransform: "none",
    //   color: "white",
    // },
    // h2: {
    //   fontFamily: "Raleway",
    //   fontWeight: 700,
    //   fontSize: "2.5rem",
    //   color: meshedBlue,
    //   lineHeight: 1.5,
    // },
    // h3: {
    //   fontFamily: "Pacifico",
    //   fontSize: "2.5rem",
    //   color: meshedBlue,
    // },
    // h4: {
    //   fontFamily: "Raleway",
    //   fontSize: "1.75rem",
    //   color: meshedBlue,
    //   fontWeight: 700,
    // },
    // h6: {
    //   fontWeight: 500,
    //   fontFamily: "Raleway",
    //   color: meshedBlue,
    // },
    // subtitle1: {
    //   fontSize: "1.25rem",
    //   fontWeight: 300,
    //   color: arcGrey,
    // },
    // subtitle2: {
    //   color: "white",
    //   fontWeight: 300,
    //   fontSize: "1.25rem",
    // },
    // body1: {
    //   fontSize: "1.25rem",
    //   color: arcGrey,
    //   fontWeight: 300,
    // },
    // caption: {
    //   fontSize: "1rem",
    //   fontWeight: 300,
    //   color: arcGrey,
    // },
    // learnButton: {
    //   borderColor: meshedBlue,
    //   borderWidth: 2,
    //   textTransform: "none",
    //   color: meshedBlue,
    //   borderRadius: 50,
    //   fontFamily: "Roboto",
    //   fontWeight: "bold",
    // },
  },
  //   overrides: {
  //     MuiInputLabel: {
  //       root: {
  //         color: meshedBlue,
  //         fontSize: "1rem",
  //       },
  //     },
  //     MuiInput: {
  //       root: {
  //         color: arcGrey,
  //         fontWeight: 300,
  //       },
  //       underline: {
  //         "&:before": {
  //           borderBottom: `2px solid ${meshedBlue}`,
  //         },
  //         "&:hover:not($disabled):not($focused):not($error):before": {
  //           borderBottom: `2px solid ${meshedBlue}`,
  //         },
  //       },
  //     },
  //   },
});
