import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import { AuthContext } from '../context/auth';
import Topbar from '../components/topbar/Topbar';
import Sidebar from '../components/sidebar/Sidebar';
import '../Admin.css';

function AuthRoute({ component: Component, user: User, ...rest }) {
  const { user } = useContext(AuthContext);
  // console.log('Usre ', user, User);
  return (
    <Route
      {...rest}
      render={(props) =>
        !user ? (
          <Redirect to="/login" />
        ) : !user.role || user.role !== 'admin' ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

export default AuthRoute;
